<template>
  <ZButton
    :link="isLink"
    :class="['buy-rv-btn', { 'darker-text': usePrimary200 }]"
    :variant="btnVariant"
    :outline="useWhite || useOutline"
    :href="runtimeConfig.public.apps.marketplace.baseURL"
    target="_blank"
    @click.prevent="buyAnRvLinkClicked"
  >
    {{ textBtn }}
  </ZButton>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{
  textBtn: string
  useHighlight?: boolean
  useWhite?: boolean
  isLink?: boolean
  usePrimary200?: boolean
  cta: string
  useOutline?: boolean
}>(), {
  useHighlight: false,
  useWhite: false,
  isLink: false,
  usePrimary200: false,
  useOutline: false,
})

const { $experiment } = useNuxtApp()
const { trackBuyRvClicked } = useTracking()
const { routeBaseName } = useBaseName()
const runtimeConfig = useRuntimeConfig()
const { isInCa, isInUs } = useGeolocation()

const buyAnRvLinkClicked = () => {
  trackBuyRvClicked({ pageSource: routeBaseName.value, cta: props.cta })

  const targetUrl
        = $experiment.isEnabled('rvz18892BuyAnRvTypeform') && (isInCa.value || isInUs.value)
          ? isInCa.value
            ? 'https://rvezy.typeform.com/to/vzPQrnXw'
            : 'https://rvezy.typeform.com/to/pW68D3i9'
          : runtimeConfig.public.apps.marketplace.baseURL

  window.open(targetUrl)
}

const btnVariant = computed(() => {
  return props.useHighlight ? 'highlight' : props.useWhite ? 'white' : 'primary'
})
</script>

<style scoped lang="scss">
.zlink {
  text-decoration: none;
  font-size: 0.875rem;
  padding-left: 0.875rem;
  padding-right: 0.875rem;

  @include media-max-size(xLarge) {
    font-size: 1rem;
    padding: 0;
  }
}
.darker-text {
  color: getColor('primary-200');
  font-size: 1rem;
  padding: 0;
  font-weight: 400;
}
</style>
